import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Unidade } from '../models/unidade';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../utils/util';

@Injectable({
  providedIn: 'root',
})
export class UnidadeService {
  constructor(private http: HttpClient) {}

  getListaUnidade(): Observable<Unidade[]> {
    return this.http
      .get<ApiResponse<Unidade>>('/unidade')
      .pipe(map(tipos => tipos.items));
  }

  getListaUnidadeAtiva(): Observable<Unidade[]> {
    return this.http
      .get<ApiResponse<Unidade>>('/unidade?Ativo=true')
      .pipe(map(tipos => tipos.items));
  }

  getUnidade(id: string): Observable<Unidade> {
    return this.http.get<Unidade>(`/unidade/${id}`);
  }

  save(form) {
    return form.id
      ? this.http.put('/unidade', form)
      : this.http.post('/unidade', form);
  }
}
