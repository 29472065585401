import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TipoAgenda } from '../models/tipo-agenda';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../utils/util';

@Injectable({
  providedIn: 'root',
})
export class TipoAgendaService {
  constructor(private http: HttpClient) {}

  getListaTipos(): Observable<TipoAgenda[]> {
    return this.http
      .get<ApiResponse<TipoAgenda>>(`/tipo-agenda?Ativo=true`)
      .pipe(map(tipos => tipos.items));
  }

  save(form) {
    return form.id
      ? this.http.put('/tipo-agenda', form)
      : this.http.post('/tipo-agenda', form);
  }
}
